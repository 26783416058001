import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../style/BarCamPage.css';

const BarCamPage = () => {
  const [showBarCam, setShowBarCam] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [imageSrc, setImageSrc] = useState('http://81.137.218.242:8080/cam_1.jpg');
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  useEffect(() => {
    // Set document title
    document.title = "Patrick's Bar ☘";

    // Add Favicon
    const faviconLink = document.createElement('link');
    faviconLink.rel = 'icon';
    faviconLink.href = require('../assets/favicon.ico');
    document.head.appendChild(faviconLink);

    // Dynamically add Google Font
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Cinzel&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (showBarCam) {
      interval = setInterval(() => {
        setImageSrc(`http://81.137.218.242:8080/cam_1.jpg?timestamp=${new Date().getTime()}`);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showBarCam]);

  const handleBarCamClick = () => {
    setShowBarCam(true);
  };

  const handleMapClick = () => {
    setShowMap(true);
  };

  const handleCloseOverlay = () => {
    setShowBarCam(false);
    setShowMap(false);
  };

  const handleAcceptCookies = () => {
    setShowCookieBanner(false);
  };

  return (
    <div className="container">
      {/* Background Image */}
      <img
        src={require('../assets/aa.jpg')}
        alt="Patrick's Bar Background"
        className="backgroundImage"
      />
      
      {/* Fixed Header */}
      <header className="header">
        <motion.h1
          className="title"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 150, damping: 15 }}
        >
         ☘ Patrick's Bar ☘
        </motion.h1>
      </header>

      {/* Unified Content Section */}
      <section className="hero">
        <motion.h2
          className="welcomeTitle"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 120, damping: 20 }}
        >
          Welcome to Patrick's Bar
        </motion.h2>
        <p className="heroDescription">Enjoy the cozy atmosphere, great drinks, and friendly company. Join us to make unforgettable memories.</p>
        <p className="heroDetails">Situated in upper Bangor, this lively Irish-themed bar is popular with both locals and the students of Bangor University. The bar has numerous TVs displaying sporting events. On the bar are three hand pumps with a regularly changing range of beers, including from North Wales breweries. On bus route towards Menai Straits, and near to the railway station.</p>

        {/* Live BarCam and Opening Times */}
        <div className="contentContainer">
          <div className="barCamContainer" onClick={handleBarCamClick}>
            <h3 className="barCamTitle">Live BarCam</h3>
            <p className="barCamDescription">See what's happening right now at Patrick's Bar.</p>
          </div>
          <div className="openingTimesContainer">
            <h3 className="openingTimesTitle">Opening Times</h3>
            <ul className="openingTimesList">
              <li className="openingTimesItem"><span>Monday:</span> <span>11:00AM - 2:00AM</span></li>
              <li className="openingTimesItem"><span>Tuesday:</span> <span>11:00AM - 2:00AM</span></li>
              <li className="openingTimesItem"><span>Wednesday:</span> <span>11:00AM - 2:30AM</span></li>
              <li className="openingTimesItem"><span>Thursday:</span> <span>11:00AM - 2:00AM</span></li>
              <li className="openingTimesItem"><span>Friday:</span> <span>11:00AM - 3:00AM</span></li>
              <li className="openingTimesItem"><span>Saturday:</span> <span>11:00AM - 3:00AM</span></li>
              <li className="openingTimesItem"><span>Sunday:</span> <span>11:00AM - 2:00AM</span></li>
            </ul>
          </div>
        </div>
        {/* Where can you find us link */}
        <p className="findUsLink" onClick={handleMapClick} style={{ cursor: 'pointer', color: '#ffd700', textDecoration: 'underline' }}>
          Where can you find us?
        </p>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="socialLinks">
          <a href="https://www.instagram.com/patricksbar.comno1" target="_blank" rel="noopener noreferrer" className="socialIcon">
            <img src={require('../assets/instagram-icon.PNG')} alt="Instagram" className="socialImage" />
          </a>
          <a href="https://www.facebook.com/patricksbar.comno1" target="_blank" rel="noopener noreferrer" className="socialIcon">
            <img src={require('../assets/facebook-icon.PNG')} alt="Facebook" className="socialImage" />
          </a>
        </div>
        <p className="footerText">May the road rise to meet you. ☘️</p>
      </footer>

      {/* Cookie Consent Banner */}
      {showCookieBanner && (
        <div className="cookieBanner">
          <p>We use cookies to ensure you get the best experience on our website. By continuing, you accept our use of cookies. 
          <button onClick={handleAcceptCookies} className="acceptButton">Accept</button></p>
        </div>
      )}

      {showBarCam && (
        <motion.div
          className="overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="overlayContent">
            <button className="closeButton" onClick={handleCloseOverlay}>Close</button>
            <motion.img
              src={imageSrc}
              alt="Live BarCam"
              className="iframe"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              onError={() => setImageSrc(null)}
            />
            {!imageSrc && (
              <p className="webcamOffline">The webcam is currently offline. Please check back later.</p>
            )}
          </div>

          
        </motion.div>
      )}

      {showMap && (
        <motion.div
          className="overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="overlayContent">
            <button className="closeButton" onClick={handleCloseOverlay}>Close</button>
            <iframe
              width="520"
              height="400"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=57-59%20Holyhead%20Rd%20Bangor+(Patricks%20Bar)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default BarCamPage;
